// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#product-title {
    font-family: 'Droid Serif';
    color: black !important;
    font-weight: 700;
}

.middle-image {
    max-width: 200px !important;
}


.info-row {
    display: flex;
    margin-bottom: 10px;
  }
  
  .info-key {
    flex: 1 1;
    font-weight: bold;
  }
  
  .info-value {
    flex: 3 1;
  }
  
  .wishtlist-btn {
    margin-left: 29rem;
  }

  .wishtlist-btn .btn {
    background-color: palevioletred;
  }


  .wishtlist-btn .btn:hover {
    background-color: palevioletred;
  }`, "",{"version":3,"sources":["webpack://./src/components/Product/ProductDetail/ProductDetail.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;AAC/B;;;AAGA;IACI,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,SAAO;IACP,iBAAiB;EACnB;;EAEA;IACE,SAAO;EACT;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,+BAA+B;EACjC;;;EAGA;IACE,+BAA+B;EACjC","sourcesContent":["#product-title {\n    font-family: 'Droid Serif';\n    color: black !important;\n    font-weight: 700;\n}\n\n.middle-image {\n    max-width: 200px !important;\n}\n\n\n.info-row {\n    display: flex;\n    margin-bottom: 10px;\n  }\n  \n  .info-key {\n    flex: 1;\n    font-weight: bold;\n  }\n  \n  .info-value {\n    flex: 3;\n  }\n  \n  .wishtlist-btn {\n    margin-left: 29rem;\n  }\n\n  .wishtlist-btn .btn {\n    background-color: palevioletred;\n  }\n\n\n  .wishtlist-btn .btn:hover {\n    background-color: palevioletred;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
