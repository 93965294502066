import OrderDetail from "../../../components/OrderDetail/OrderDetail"
import "./index.css"
const OrderPage =() => {
    return (
        <>
            <OrderDetail/>
        </>
    )
}

export default OrderPage