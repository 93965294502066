// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

#root {
  background-color: #F7F7F8;
}

.btn-default {
  background-color: #7355F7;
  color: white;
}

/* .btn:hover {
  background-color: #4F29F5;
  color: white;
} */

.rmsc .btn {
  background-color: black;
  color: white;
}

.badge {
  margin-top: 7px;
  border-radius: 9px;
}


#lblWishlistCount {
    font-size: 12px;
    background: #ff0000;
    color: #fff;
    padding: 1px 5px;
    vertical-align: top;
    margin-left: -5px; 
}
.counter {

  display: inline;
  background-color: #ff0000; /* Background color for the counter */
  color: #ffffff; /* Text color for the counter */
  padding: 6px 10px; /* Padding to provide some spacing around the counter */
  border-radius: 4px; /* Border radius for rounded corners */
  font-weight: bold; /* Make the text bold if desired */
  font-size: 10px;

  vertical-align: top;

}

@media (max-width: 991px) {
  .hide-mobile {
    display: none; } }`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;;;GAGG;;AAEH;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;;AAGA;IACI,eAAe;IACf,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;AACrB;AACA;;EAEE,eAAe;EACf,yBAAyB,EAAE,qCAAqC;EAChE,cAAc,EAAE,+BAA+B;EAC/C,iBAAiB,EAAE,uDAAuD;EAC1E,kBAAkB,EAAE,sCAAsC;EAC1D,iBAAiB,EAAE,kCAAkC;EACrD,eAAe;;EAEf,mBAAmB;;AAErB;;AAEA;EACE;IACE,aAAa,EAAE,EAAE","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: sans-serif;\n}\n\n#root {\n  background-color: #F7F7F8;\n}\n\n.btn-default {\n  background-color: #7355F7;\n  color: white;\n}\n\n/* .btn:hover {\n  background-color: #4F29F5;\n  color: white;\n} */\n\n.rmsc .btn {\n  background-color: black;\n  color: white;\n}\n\n.badge {\n  margin-top: 7px;\n  border-radius: 9px;\n}\n\n\n#lblWishlistCount {\n    font-size: 12px;\n    background: #ff0000;\n    color: #fff;\n    padding: 1px 5px;\n    vertical-align: top;\n    margin-left: -5px; \n}\n.counter {\n\n  display: inline;\n  background-color: #ff0000; /* Background color for the counter */\n  color: #ffffff; /* Text color for the counter */\n  padding: 6px 10px; /* Padding to provide some spacing around the counter */\n  border-radius: 4px; /* Border radius for rounded corners */\n  font-weight: bold; /* Make the text bold if desired */\n  font-size: 10px;\n\n  vertical-align: top;\n\n}\n\n@media (max-width: 991px) {\n  .hide-mobile {\n    display: none; } }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
