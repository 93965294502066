// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.track-line {
    height: 2px !important;
    background-color: #488978;
    opacity: 1;
}

.dot {
    height: 10px;
    width: 10px;
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 0px;
    background-color: #488978;
    border-radius: 50%;
    display: inline-block
}

.big-dot {
    height: 25px;
    width: 25px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    background-color: #488978;
    border-radius: 50%;
    display: inline-block;
}

.big-dot i {
    font-size: 12px;
}

.card-stepper {
    z-index: 0
}`, "",{"version":3,"sources":["webpack://./src/components/OrderDetail/OrderDetail.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,yBAAyB;IACzB,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB;AACJ;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;AACJ","sourcesContent":[".track-line {\n    height: 2px !important;\n    background-color: #488978;\n    opacity: 1;\n}\n\n.dot {\n    height: 10px;\n    width: 10px;\n    margin-left: 3px;\n    margin-right: 3px;\n    margin-top: 0px;\n    background-color: #488978;\n    border-radius: 50%;\n    display: inline-block\n}\n\n.big-dot {\n    height: 25px;\n    width: 25px;\n    margin-left: 0px;\n    margin-right: 0px;\n    margin-top: 0px;\n    background-color: #488978;\n    border-radius: 50%;\n    display: inline-block;\n}\n\n.big-dot i {\n    font-size: 12px;\n}\n\n.card-stepper {\n    z-index: 0\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
