// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noti-menu {
    max-height: 300px;
    overflow-y: auto;
}
.noti-popover-contained {
    width: fit-content;
}

.noti-menu li:hover{
    background-color: rgba(230, 218, 218, 0.97) !important;
    color: black !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Profile/Notification/BriefNotification.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,sDAAsD;IACtD,uBAAuB;AAC3B","sourcesContent":[".noti-menu {\n    max-height: 300px;\n    overflow-y: auto;\n}\n.noti-popover-contained {\n    width: fit-content;\n}\n\n.noti-menu li:hover{\n    background-color: rgba(230, 218, 218, 0.97) !important;\n    color: black !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
