// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-tag {
    width: 100%;
}

.filter-price {
    height: 60px;
}

.filter h5 {
    font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/components/SearchProduct/SearchSideBar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".filter-tag {\n    width: 100%;\n}\n\n.filter-price {\n    height: 60px;\n}\n\n.filter h5 {\n    font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
