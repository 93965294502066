import { Container } from 'react-bootstrap'
import './index.css'

const Contact = () => {
  return (
        <Container>
                 <p>contact</p>
        </Container>

  )
}

export default Contact
