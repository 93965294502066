// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.img-sm {
    width: 46px;
    height: 46px;
}


.panel-body {
    padding: 10px
}


.media-block .media-left {
    display: block;
    float: left
}



.media-block .media-body {
    display: block;
    overflow: hidden;
    width: auto;
    margin-left: 15px;
}




.btn-reply {
    background-color: #2ECC71 !important;
    margin-left: 5px;
}

.btn-like {
    background-color: white !important;
    margin-right: 5px;
}

.btn-unlike {
    background-color: white !important;
}

.btn-submit {
    margin-left: 90%;
}

.reply-section{
    width: 500px;
}

.vote-icon {
    font-size: 20px; 
    color:#606060; 
    text-align: center;
}

.vote-icon  a{
    color: #606060;
    border: black 1px solid;
    border-radius: 50%;
  }

  .vote-icon-up {
    font-size: 19px; 
  }
  
  .vote-icon  a:hover{
    background-color: grey;
    color: white;
    border: black 1px solid;
    border-radius: 70%;
  }
 
  .vote-icon-up.disabled {
    opacity: 0.5; 
    cursor: not-allowed; 
  }
  `, "",{"version":3,"sources":["webpack://./src/components/FeedBack/CommentSection/Comment.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,YAAY;AAChB;;;AAGA;IACI;AACJ;;;AAGA;IACI,cAAc;IACd;AACJ;;;;AAIA;IACI,cAAc;IACd,gBAAgB;IAChB,WAAW;IACX,iBAAiB;AACrB;;;;;AAKA;IACI,oCAAoC;IACpC,gBAAgB;AACpB;;AAEA;IACI,kCAAkC;IAClC,iBAAiB;AACrB;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,uBAAuB;IACvB,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,sBAAsB;IACtB,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,mBAAmB;EACrB","sourcesContent":["\n.img-sm {\n    width: 46px;\n    height: 46px;\n}\n\n\n.panel-body {\n    padding: 10px\n}\n\n\n.media-block .media-left {\n    display: block;\n    float: left\n}\n\n\n\n.media-block .media-body {\n    display: block;\n    overflow: hidden;\n    width: auto;\n    margin-left: 15px;\n}\n\n\n\n\n.btn-reply {\n    background-color: #2ECC71 !important;\n    margin-left: 5px;\n}\n\n.btn-like {\n    background-color: white !important;\n    margin-right: 5px;\n}\n\n.btn-unlike {\n    background-color: white !important;\n}\n\n.btn-submit {\n    margin-left: 90%;\n}\n\n.reply-section{\n    width: 500px;\n}\n\n.vote-icon {\n    font-size: 20px; \n    color:#606060; \n    text-align: center;\n}\n\n.vote-icon  a{\n    color: #606060;\n    border: black 1px solid;\n    border-radius: 50%;\n  }\n\n  .vote-icon-up {\n    font-size: 19px; \n  }\n  \n  .vote-icon  a:hover{\n    background-color: grey;\n    color: white;\n    border: black 1px solid;\n    border-radius: 70%;\n  }\n \n  .vote-icon-up.disabled {\n    opacity: 0.5; \n    cursor: not-allowed; \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
