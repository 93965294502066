// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-section-body {
    background-color: #CEEACE;
}

.custom-btn {
    background-color: #2ECC71 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatSection/ChatSection.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":[".chat-section-body {\n    background-color: #CEEACE;\n}\n\n.custom-btn {\n    background-color: #2ECC71 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
