// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-section h4 {
    font-size: 20px;
    color: #222325;
    font-weight: 700;
    margin: auto auto;
}




.review-section .fit-progressbar-bar .fit-progressbar-background {
    position: relative;
    height: 8px;
    background: #efeff0;
    flex-grow: 1;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    ;
    border-radius: 999px;
}

.review-section .stars-counters tr .star-progress-bar .progress-fill {
    background-color: #ffb33e;
}

.review-section .fit-progressbar-bar .progress-fill {

    height: 100%;
    position: absolute;
    left: 0;
    z-index: 1;
    border-radius: 999px;
}



.review-section .stars-counters td {
    white-space: nowrap;
}

.review-section .stars-counters tr .progress-bar-container {
    width: 100%;
    padding: 0 10px 0 6px;
    margin: auto;
}

.rating .fa-star {
    margin-right: 5px; /* Adjust the value to control the spacing */
  }

.rating-total {
    color: #ffb33e;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/FeedBack/RatingSection/RatingSection.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,iBAAiB;AACrB;;;;;AAKA;IACI,kBAAkB;IAClB,WAAW;IACX,mBAAmB;IAGnB,YAAY;IACZ,wCAAwC;IACxC,yBAAyB;;IAEzB,oBAAoB;AACxB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;;IAEI,YAAY;IACZ,kBAAkB;IAClB,OAAO;IACP,UAAU;IACV,oBAAoB;AACxB;;;;AAIA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,iBAAiB,EAAE,4CAA4C;EACjE;;AAEF;IACI,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".review-section h4 {\n    font-size: 20px;\n    color: #222325;\n    font-weight: 700;\n    margin: auto auto;\n}\n\n\n\n\n.review-section .fit-progressbar-bar .fit-progressbar-background {\n    position: relative;\n    height: 8px;\n    background: #efeff0;\n    -webkit-box-flex: 1;\n    -ms-flex-positive: 1;\n    flex-grow: 1;\n    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);\n    background-color: #ffffff;\n    ;\n    border-radius: 999px;\n}\n\n.review-section .stars-counters tr .star-progress-bar .progress-fill {\n    background-color: #ffb33e;\n}\n\n.review-section .fit-progressbar-bar .progress-fill {\n\n    height: 100%;\n    position: absolute;\n    left: 0;\n    z-index: 1;\n    border-radius: 999px;\n}\n\n\n\n.review-section .stars-counters td {\n    white-space: nowrap;\n}\n\n.review-section .stars-counters tr .progress-bar-container {\n    width: 100%;\n    padding: 0 10px 0 6px;\n    margin: auto;\n}\n\n.rating .fa-star {\n    margin-right: 5px; /* Adjust the value to control the spacing */\n  }\n\n.rating-total {\n    color: #ffb33e;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
