// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-product-item:hover {
    transform: scale(1.08);
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.my-product-item {
    overflow: hidden;
    text-align: center;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
    transition: 0.3s;
}

.product-status {
    color: white;
    padding: 20px;
    border-radius: 0.75rem
}

.product-status h4 {
    font-weight: 900px;
}`, "",{"version":3,"sources":["webpack://./src/components/Profile/MyProduct/MyProduct.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,4CAA4C;AAChD;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,6CAA6C;IAC7C,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb;AACJ;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".my-product-item:hover {\n    transform: scale(1.08);\n    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);\n}\n\n.my-product-item {\n    overflow: hidden;\n    text-align: center;\n    border-radius: 5px;\n    background: #fff;\n    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);\n    transition: 0.3s;\n}\n\n.product-status {\n    color: white;\n    padding: 20px;\n    border-radius: 0.75rem\n}\n\n.product-status h4 {\n    font-weight: 900px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
