// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.relate-product-header {
    background-color: #7355F7 !important;
}   

.relate-product-item:hover {
    background-color: aliceblue;

}

.relate-product-item {
    padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/RelateProduct/RelateProduct.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;;AAEA;IACI,2BAA2B;;AAE/B;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".relate-product-header {\n    background-color: #7355F7 !important;\n}   \n\n.relate-product-item:hover {\n    background-color: aliceblue;\n\n}\n\n.relate-product-item {\n    padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
