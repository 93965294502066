import CreateThread from "../../../components/Thread/CreateThread"
import "./index.css"
const ThreadCreatePage =() => {
    return (
        <>
            <CreateThread/>
        </>
    )
}

export default ThreadCreatePage;