// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-check-input {
    
    border-width: 2px !important;
    border-style: solid !important;
    border-color: black !important;
}

.form-check-input:checked {
    background-color: #4b24f5 !important;
    border-color: #4b24f5 !important;
    border-width: 2px !important;
    border-style: solid !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Category/CategoryFilter/CategoryFilter.css"],"names":[],"mappings":"AAAA;;IAEI,4BAA4B;IAC5B,8BAA8B;IAC9B,8BAA8B;AAClC;;AAEA;IACI,oCAAoC;IACpC,gCAAgC;IAChC,4BAA4B;IAC5B,8BAA8B;AAClC","sourcesContent":[".form-check-input {\n    \n    border-width: 2px !important;\n    border-style: solid !important;\n    border-color: black !important;\n}\n\n.form-check-input:checked {\n    background-color: #4b24f5 !important;\n    border-color: #4b24f5 !important;\n    border-width: 2px !important;\n    border-style: solid !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
