// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin-top: 20px;
}

.glyphicon {
    margin-right: 5px;
}

.rating .glyphicon {
    font-size: 22px;
}

.rating-num {
    margin-top: 0px;
    font-size: 54px;
}

.progress {
    margin-bottom: 5px;
}

.progress-bar {
    text-align: left;
}

.rating-desc .col-md-3 {
    padding-right: 0px;
}

.sr-only {
    margin-left: 5px;
    overflow: visible;
    clip: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/FeedBack/Feedback.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,UAAU;AACd","sourcesContent":["body {\n    margin-top: 20px;\n}\n\n.glyphicon {\n    margin-right: 5px;\n}\n\n.rating .glyphicon {\n    font-size: 22px;\n}\n\n.rating-num {\n    margin-top: 0px;\n    font-size: 54px;\n}\n\n.progress {\n    margin-bottom: 5px;\n}\n\n.progress-bar {\n    text-align: left;\n}\n\n.rating-desc .col-md-3 {\n    padding-right: 0px;\n}\n\n.sr-only {\n    margin-left: 5px;\n    overflow: visible;\n    clip: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
