// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popImg {
    height: 45px;
    border-radius: 50%;
    cursor: pointer !important;
    margin: 0.2rem 0 0 0.7rem;
}




.dropdown-menu {
    padding-right: 200px;
}

#popover-contained {
    width: 170px;
}

.menu {
    list-style: none;
    padding: 0;
}

.menu li {
    padding: 8px 16px;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s;
}

.menu li a {
    text-decoration: none;
    color: #333;
}

.menu .personal-li:hover {
    background-color: #4B24F5;
    color: white;
}




.menu li .link:hover {
    color: white;
}


.right {
    margin-left: 10px;
}

.member-icon {
    margin-top: 12px;
    height: 25px;
}

`, "",{"version":3,"sources":["webpack://./src/components/UserHeader/index.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,0BAA0B;IAC1B,yBAAyB;AAC7B;;;;;AAKA;IACI,oBAAoB;AACxB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,qBAAqB;IACrB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;;;;AAKA;IACI,YAAY;AAChB;;;AAGA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".popImg {\n    height: 45px;\n    border-radius: 50%;\n    cursor: pointer !important;\n    margin: 0.2rem 0 0 0.7rem;\n}\n\n\n\n\n.dropdown-menu {\n    padding-right: 200px;\n}\n\n#popover-contained {\n    width: 170px;\n}\n\n.menu {\n    list-style: none;\n    padding: 0;\n}\n\n.menu li {\n    padding: 8px 16px;\n    font-size: 16px;\n    color: #333;\n    cursor: pointer;\n    transition: background-color 0.3s;\n}\n\n.menu li a {\n    text-decoration: none;\n    color: #333;\n}\n\n.menu .personal-li:hover {\n    background-color: #4B24F5;\n    color: white;\n}\n\n\n\n\n.menu li .link:hover {\n    color: white;\n}\n\n\n.right {\n    margin-left: 10px;\n}\n\n.member-icon {\n    margin-top: 12px;\n    height: 25px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
