// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.category-statistic {
    margin-left: 20px;
    display: inline-block;
    margin-right: 20px;
    /* Adjust as needed to add space between elements */
}

.category-statistic span {
    font-weight: 900;
}

.category-statistic p {
    font-size: small;
    font-weight: lighter;
}


.vertical-line {
    border-right: 2px solid #7355F7;
    height: 100%;
}

.category-filter {
    margin-left: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Category/CategoryStatistic/CategoryStatistic.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;IACjB,qBAAqB;IACrB,kBAAkB;IAClB,mDAAmD;AACvD;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;AACxB;;;AAGA;IACI,+BAA+B;IAC/B,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["\n.category-statistic {\n    margin-left: 20px;\n    display: inline-block;\n    margin-right: 20px;\n    /* Adjust as needed to add space between elements */\n}\n\n.category-statistic span {\n    font-weight: 900;\n}\n\n.category-statistic p {\n    font-size: small;\n    font-weight: lighter;\n}\n\n\n.vertical-line {\n    border-right: 2px solid #7355F7;\n    height: 100%;\n}\n\n.category-filter {\n    margin-left: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
