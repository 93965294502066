// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-header {
    background-color: blueviolet;
}

.small-image {
    max-width: 30px;
    /* Adjust as needed */
    margin-right: 15px;
    padding-bottom: 5px;
}

.tag {
    margin-right:  3px;
}

/* Custom Pagination Styles */
.pagination .page-item.active .page-link {
  background-color: #4b24f5;
  border-color: #4b24f5;
  color: white;
}

.pagination .page-item .page-link {
  color: black;
  
}

.pagination .page-item .page-link:hover {
  background-color: #4b24f5;
  border-color: #4b24f5;
  color: white;
}

`, "",{"version":3,"sources":["webpack://./src/components/Product/Product.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;;AAEA;IACI,eAAe;IACf,qBAAqB;IACrB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA,6BAA6B;AAC7B;EACE,yBAAyB;EACzB,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,YAAY;;AAEd;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,YAAY;AACd","sourcesContent":[".card-header {\n    background-color: blueviolet;\n}\n\n.small-image {\n    max-width: 30px;\n    /* Adjust as needed */\n    margin-right: 15px;\n    padding-bottom: 5px;\n}\n\n.tag {\n    margin-right:  3px;\n}\n\n/* Custom Pagination Styles */\n.pagination .page-item.active .page-link {\n  background-color: #4b24f5;\n  border-color: #4b24f5;\n  color: white;\n}\n\n.pagination .page-item .page-link {\n  color: black;\n  \n}\n\n.pagination .page-item .page-link:hover {\n  background-color: #4b24f5;\n  border-color: #4b24f5;\n  color: white;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
