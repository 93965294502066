// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.categories {
    background-color: #F6F7F8;
}

.categories .box {
    padding: 30px;
    box-shadow: 0px 0 5px rgba(1, 41, 112, 0.08);
    text-align: center;
    transition: 0.3s;
    height: 100%;
}

.categories .box img {
    padding: 30px 50px;
    transition: 0.5s;
    transform: scale(1.1);
}

.categories .box h3 {
    font-size: 24px;
    color: #012970;
    font-weight: 700;
    margin-bottom: 18px;
}

.categories .box:hover {
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
}

.categories .box:hover img {
    transform: scale(1);
}`, "",{"version":3,"sources":["webpack://./src/components/Landing/CategoryList/index.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,4CAA4C;IAC5C,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,6CAA6C;AACjD;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".categories {\n    background-color: #F6F7F8;\n}\n\n.categories .box {\n    padding: 30px;\n    box-shadow: 0px 0 5px rgba(1, 41, 112, 0.08);\n    text-align: center;\n    transition: 0.3s;\n    height: 100%;\n}\n\n.categories .box img {\n    padding: 30px 50px;\n    transition: 0.5s;\n    transform: scale(1.1);\n}\n\n.categories .box h3 {\n    font-size: 24px;\n    color: #012970;\n    font-weight: 700;\n    margin-bottom: 18px;\n}\n\n.categories .box:hover {\n    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);\n}\n\n.categories .box:hover img {\n    transform: scale(1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
