// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/assets/images/all-bg-title.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.all-title-box{
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center;
    -ms-background-size: cover;
    background-size: cover;
    text-align: center;
    background-attachment: fixed;
    padding: 70px 0px;
    position: relative;
    z-index: 0;
}
.all-title-box::before{
    background: rgba(0,0,0,0.6);
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
}
.all-title-box h2{
    font-size: 28px;
    font-weight: 700;
    color: white;
    padding-bottom: 20px;
}
.all-title-box .breadcrumb{
    background: #d33b33;
    margin: 0px;
    display: inline-block;
    border-radius: 0px;
    padding: 20px;
}
.all-title-box .breadcrumb li{
    display: inline-block;
    color: #010101;
    font-size: 16px;
}
.all-title-box .breadcrumb li a{
    color: #ffffff ;
    font-size: 16px;
    font-weight: 100px;
    text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/TitleBox/TitleBox.css"],"names":[],"mappings":";AACA;IACI,2EAAyF;IAGzF,0BAA0B;IAE1B,sBAAsB;IACtB,kBAAkB;IAClB,4BAA4B;IAC5B,iBAAiB;IACjB,kBAAkB;IAClB,UAAU;AACd;AACA;IACI,2BAA2B;IAC3B,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,YAAY;IACZ,SAAS;IACT,QAAQ;AACZ;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,oBAAoB;AACxB;AACA;IACI,mBAAmB;IACnB,WAAW;IACX,qBAAqB;IACrB,kBAAkB;IAClB,aAAa;AACjB;AACA;IACI,qBAAqB;IACrB,cAAc;IACd,eAAe;AACnB;AACA;IACI,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,qBAAqB;AACzB","sourcesContent":["\n.all-title-box{\n    background: url(\"../../../public/assets/images/all-bg-title.jpg\") no-repeat center center;\n    -webkit-background-size: cover;\n    -moz-background-size: cover;\n    -ms-background-size: cover;\n    -o-background-size: cover;\n    background-size: cover;\n    text-align: center;\n    background-attachment: fixed;\n    padding: 70px 0px;\n    position: relative;\n    z-index: 0;\n}\n.all-title-box::before{\n    background: rgba(0,0,0,0.6);\n    content: \"\";\n    position: absolute;\n    z-index: -1;\n    width: 100%;\n    height: 100%;\n    left: 0px;\n    top: 0px;\n}\n.all-title-box h2{\n    font-size: 28px;\n    font-weight: 700;\n    color: white;\n    padding-bottom: 20px;\n}\n.all-title-box .breadcrumb{\n    background: #d33b33;\n    margin: 0px;\n    display: inline-block;\n    border-radius: 0px;\n    padding: 20px;\n}\n.all-title-box .breadcrumb li{\n    display: inline-block;\n    color: #010101;\n    font-size: 16px;\n}\n.all-title-box .breadcrumb li a{\n    color: #ffffff ;\n    font-size: 16px;\n    font-weight: 100px;\n    text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
