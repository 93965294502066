// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider:after,
.divider:before {
content: "";
flex: 1 1;
height: 1px;
background: #eee;
}

.box-shadow {
	box-shadow:         0px 10px 20px 0px rgba(50, 50, 50, 0.52)
}`, "",{"version":3,"sources":["webpack://./src/components/RegisterForm/index.css"],"names":[],"mappings":"AAAA;;AAEA,WAAW;AACX,SAAO;AACP,WAAW;AACX,gBAAgB;AAChB;;AAEA;CAGC;AACD","sourcesContent":[".divider:after,\n.divider:before {\ncontent: \"\";\nflex: 1;\nheight: 1px;\nbackground: #eee;\n}\n\n.box-shadow {\n\t-webkit-box-shadow: 0px 10px 20px 0px rgba(50, 50, 50, 0.52);\n\t-moz-box-shadow:    0px 10px 20px 0px rgba(50, 50, 50, 0.52);\n\tbox-shadow:         0px 10px 20px 0px rgba(50, 50, 50, 0.52)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
