// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.category-hero {
    position: relative;
    width: 100%;
    height: 20vh; /* Adjust the height as needed */
    overflow: hidden;

}

.category-hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* This ensures the image covers the entire container */
}

.category-hero-text h1 {
    color: black;
    font-weight: 900;
}

.category-hero-text p {
    color: black;
    font-weight: 500;
}

.category-hero-text {
    position: absolute;
    top: 70%;
    left: 30%;

    transform: translate(-20%, -50%);
    text-align: center;
    color: #fff; /* Set text color */
}


  .category-hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6); /* Adjust the opacity as needed */
    z-index: 0;
  }
  
  .category-hero p{
    font-size: 20px;
    font-weight: 700;
    color: white;
    padding-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Category/CategoryHero/CategoryHero.css"],"names":[],"mappings":";;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY,EAAE,gCAAgC;IAC9C,gBAAgB;;AAEpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB,EAAE,uDAAuD;AAC9E;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;;IAET,gCAAgC;IAChC,kBAAkB;IAClB,WAAW,EAAE,mBAAmB;AACpC;;;EAGE;IACE,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,2BAA2B,EAAE,iCAAiC;IAC9D,UAAU;EACZ;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,oBAAoB;AACxB","sourcesContent":["\n\n.category-hero {\n    position: relative;\n    width: 100%;\n    height: 20vh; /* Adjust the height as needed */\n    overflow: hidden;\n\n}\n\n.category-hero-image {\n    width: 100%;\n    height: 100%;\n    object-fit: cover; /* This ensures the image covers the entire container */\n}\n\n.category-hero-text h1 {\n    color: black;\n    font-weight: 900;\n}\n\n.category-hero-text p {\n    color: black;\n    font-weight: 500;\n}\n\n.category-hero-text {\n    position: absolute;\n    top: 70%;\n    left: 30%;\n\n    transform: translate(-20%, -50%);\n    text-align: center;\n    color: #fff; /* Set text color */\n}\n\n\n  .category-hero::before {\n    content: \"\";\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0,0,0,0.6); /* Adjust the opacity as needed */\n    z-index: 0;\n  }\n  \n  .category-hero p{\n    font-size: 20px;\n    font-weight: 700;\n    color: white;\n    padding-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
