// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.comment-area textarea {
resize: none;
border: 1px solid #FFC107
}

.form-control:focus {
color: #495057;
background-color: #fff;
border-color: #ffffff;
outline: 0;
box-shadow: 0 0 0 1px #FFC107 !important
}



.leave-comment-rating {
display: inline-flex;
margin-top: -10px;
flex-direction: row-reverse;
}

.rating>input {
display: none
}

.rating>label {
position: relative;
width: 28px;
font-size: 35px;
color: #FFC107;
cursor: pointer;
}

.rating>label::before {
content: "\\2605";
position: absolute;
opacity: 0
}

.rating>label:hover:before,
.rating>label:hover~label:before {
opacity: 1 !important
}

.rating>input:checked~label:before {
opacity: 1
}

.rating:hover>input:checked~label:before {
opacity: 0.4
}

.send {
    margin-left: 600px;
}`, "",{"version":3,"sources":["webpack://./src/components/FeedBack/LeaveComment/LeaveComment.css"],"names":[],"mappings":";AACA;AACA,YAAY;AACZ;AACA;;AAEA;AACA,cAAc;AACd,sBAAsB;AACtB,qBAAqB;AACrB,UAAU;AACV;AACA;;;;AAIA;AACA,oBAAoB;AACpB,iBAAiB;AACjB,2BAA2B;AAC3B;;AAEA;AACA;AACA;;AAEA;AACA,kBAAkB;AAClB,WAAW;AACX,eAAe;AACf,cAAc;AACd,eAAe;AACf;;AAEA;AACA,gBAAgB;AAChB,kBAAkB;AAClB;AACA;;AAEA;;AAEA;AACA;;AAEA;AACA;AACA;;AAEA;AACA;AACA;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["\n.comment-area textarea {\nresize: none;\nborder: 1px solid #FFC107\n}\n\n.form-control:focus {\ncolor: #495057;\nbackground-color: #fff;\nborder-color: #ffffff;\noutline: 0;\nbox-shadow: 0 0 0 1px #FFC107 !important\n}\n\n\n\n.leave-comment-rating {\ndisplay: inline-flex;\nmargin-top: -10px;\nflex-direction: row-reverse;\n}\n\n.rating>input {\ndisplay: none\n}\n\n.rating>label {\nposition: relative;\nwidth: 28px;\nfont-size: 35px;\ncolor: #FFC107;\ncursor: pointer;\n}\n\n.rating>label::before {\ncontent: \"\\2605\";\nposition: absolute;\nopacity: 0\n}\n\n.rating>label:hover:before,\n.rating>label:hover~label:before {\nopacity: 1 !important\n}\n\n.rating>input:checked~label:before {\nopacity: 1\n}\n\n.rating:hover>input:checked~label:before {\nopacity: 0.4\n}\n\n.send {\n    margin-left: 600px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
