// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comment-list {
  max-height: 700px;
  /* Set a maximum height for the comment list */
  overflow-y: auto;
  /* Enable vertical scrolling if content exceeds the height */
}

/* If necessary, you may also want to set a maximum height for each individual comment */
.Comment {
  max-height: 300px;
  /* Set a maximum height for each comment */
  overflow-y: auto;
  /* Enable vertical scrolling if content exceeds the height */
}

/* If you want to style the scrollbar, you can use the following CSS */
/* Note that this may not work in all browsers (e.g., Firefox) without vendor prefixes */
/* You may need to add prefixes for cross-browser compatibility */

.comment-list::-webkit-scrollbar {
  width: 10px;
  /* Set the width of the scrollbar */
}

.comment-list::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Set the color of the scrollbar thumb */
}

.comment-list::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  /* Set the color of the scrollbar track */
}

`, "",{"version":3,"sources":["webpack://./src/components/FeedBack/CommentSection/CommentList.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,8CAA8C;EAC9C,gBAAgB;EAChB,4DAA4D;AAC9D;;AAEA,wFAAwF;AACxF;EACE,iBAAiB;EACjB,0CAA0C;EAC1C,gBAAgB;EAChB,4DAA4D;AAC9D;;AAEA,sEAAsE;AACtE,wFAAwF;AACxF,iEAAiE;;AAEjE;EACE,WAAW;EACX,mCAAmC;AACrC;;AAEA;EACE,sBAAsB;EACtB,yCAAyC;AAC3C;;AAEA;EACE,yBAAyB;EACzB,yCAAyC;AAC3C","sourcesContent":[".comment-list {\n  max-height: 700px;\n  /* Set a maximum height for the comment list */\n  overflow-y: auto;\n  /* Enable vertical scrolling if content exceeds the height */\n}\n\n/* If necessary, you may also want to set a maximum height for each individual comment */\n.Comment {\n  max-height: 300px;\n  /* Set a maximum height for each comment */\n  overflow-y: auto;\n  /* Enable vertical scrolling if content exceeds the height */\n}\n\n/* If you want to style the scrollbar, you can use the following CSS */\n/* Note that this may not work in all browsers (e.g., Firefox) without vendor prefixes */\n/* You may need to add prefixes for cross-browser compatibility */\n\n.comment-list::-webkit-scrollbar {\n  width: 10px;\n  /* Set the width of the scrollbar */\n}\n\n.comment-list::-webkit-scrollbar-thumb {\n  background-color: #888;\n  /* Set the color of the scrollbar thumb */\n}\n\n.comment-list::-webkit-scrollbar-track {\n  background-color: #f0f0f0;\n  /* Set the color of the scrollbar track */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
