// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
nav ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

nav ul li a {
	display: block;
	padding: 10px 15px;
	color: black;
	text-decoration: none;
	transition: 0.2s linear;
}
nav ul li a:hover {
	background: #F5F5F5;
	color: black;
}


nav ul li ul li a {
	
	border-left: 4px solid transparent;
	padding: 10px 40px;
}

.menu-active a {
	background: #7355F7;
	color: #fff;
}

.sub-menu-active {

	font-weight: 900;
	background: #F5F5F5;
	color: black;
	
	border-left: 4px solid black;
}`, "",{"version":3,"sources":["webpack://./src/components/SideBar/SubMenu.css"],"names":[],"mappings":";AACA;CACC,gBAAgB;CAChB,SAAS;CACT,UAAU;AACX;;AAEA;CACC,cAAc;CACd,kBAAkB;CAClB,YAAY;CACZ,qBAAqB;CAKrB,uBAAuB;AACxB;AACA;CACC,mBAAmB;CACnB,YAAY;AACb;;;AAGA;;CAEC,kCAAkC;CAClC,kBAAkB;AACnB;;AAEA;CACC,mBAAmB;CACnB,WAAW;AACZ;;AAEA;;CAEC,gBAAgB;CAChB,mBAAmB;CACnB,YAAY;;CAEZ,4BAA4B;AAC7B","sourcesContent":["\nnav ul {\n\tlist-style: none;\n\tmargin: 0;\n\tpadding: 0;\n}\n\nnav ul li a {\n\tdisplay: block;\n\tpadding: 10px 15px;\n\tcolor: black;\n\ttext-decoration: none;\n\t-webkit-transition: 0.2s linear;\n\t-moz-transition: 0.2s linear;\n\t-ms-transition: 0.2s linear;\n\t-o-transition: 0.2s linear;\n\ttransition: 0.2s linear;\n}\nnav ul li a:hover {\n\tbackground: #F5F5F5;\n\tcolor: black;\n}\n\n\nnav ul li ul li a {\n\t\n\tborder-left: 4px solid transparent;\n\tpadding: 10px 40px;\n}\n\n.menu-active a {\n\tbackground: #7355F7;\n\tcolor: #fff;\n}\n\n.sub-menu-active {\n\n\tfont-weight: 900;\n\tbackground: #F5F5F5;\n\tcolor: black;\n\t\n\tborder-left: 4px solid black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
