// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment {
    text-align: center;
    padding: 40px 0;
}

.payment h1 {
    color: #88B04B;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
}

.payment p {
    color: #404F5E;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-size: 20px;
    margin: 0;
}

.payment i {
    color: #9ABC66;
    font-size: 100px;
    line-height: 200px;
    margin-left: -15px;
}

.payment .card {
    background: white;
    padding: 60px;
    border-radius: 4px;
    box-shadow: 0 2px 3px #EBF0F5;
    display: inline-block;
    margin: 0 auto;
}

.checkmark-div {
    border-radius: 200px;
    height: 200px;
    width: 200px;
    background: #F7F7F8;
    margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/Main/PaymentSucess/PaymentSucess.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,wDAAwD;IACxD,gBAAgB;IAChB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,wDAAwD;IACxD,eAAe;IACf,SAAS;AACb;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,6BAA6B;IAC7B,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,oBAAoB;IACpB,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,cAAc;AAClB","sourcesContent":[".payment {\n    text-align: center;\n    padding: 40px 0;\n}\n\n.payment h1 {\n    color: #88B04B;\n    font-family: \"Nunito Sans\", \"Helvetica Neue\", sans-serif;\n    font-weight: 900;\n    font-size: 40px;\n    margin-bottom: 10px;\n}\n\n.payment p {\n    color: #404F5E;\n    font-family: \"Nunito Sans\", \"Helvetica Neue\", sans-serif;\n    font-size: 20px;\n    margin: 0;\n}\n\n.payment i {\n    color: #9ABC66;\n    font-size: 100px;\n    line-height: 200px;\n    margin-left: -15px;\n}\n\n.payment .card {\n    background: white;\n    padding: 60px;\n    border-radius: 4px;\n    box-shadow: 0 2px 3px #EBF0F5;\n    display: inline-block;\n    margin: 0 auto;\n}\n\n.checkmark-div {\n    border-radius: 200px;\n    height: 200px;\n    width: 200px;\n    background: #F7F7F8;\n    margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
