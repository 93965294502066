import './index.css'
import RegisterForm from '../../../components/RegisterForm'

const Register = () => {
  return (
       <RegisterForm/>
  )
}

export default Register
