// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multi-select {
    max-width: 212px;
}

.product-draft:hover {
    background-color: #FDA4AF;
}

.product-draft {
    background-color: #FFE4E6;
    border-radius: 1rem;
    padding: 10px;
    border: none;
}

.require {
    color: red;
}`, "",{"version":3,"sources":["webpack://./src/pages/Main/UploadProduct/UploadProduct.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".multi-select {\n    max-width: 212px;\n}\n\n.product-draft:hover {\n    background-color: #FDA4AF;\n}\n\n.product-draft {\n    background-color: #FFE4E6;\n    border-radius: 1rem;\n    padding: 10px;\n    border: none;\n}\n\n.require {\n    color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
